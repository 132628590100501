import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './all.sass';
import useSiteMetadata from './SiteMetadata';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />

        {/* Cookie Consent by https://www.FreePrivacyPolicy.com */}
        <script
          type="text/javascript"
          src="//www.freeprivacypolicy.com/public/cookie-consent/3.1.0/cookie-consent.js"
        ></script>
        <script type="text/javascript">
          {`
            function ready(callbackFunc) {
              if (document.readyState !== 'loading') {
                // Document is already ready, call the callback directly
                callbackFunc();
              } else if (document.addEventListener) {
                // All modern browsers to register DOMContentLoaded
                document.addEventListener('DOMContentLoaded', callbackFunc);
              } else {
                // Old IE browsers
                document.attachEvent('onreadystatechange', function() {
                  if (document.readyState === 'complete') {
                    callbackFunc();
                  }
                });
              }
            }

            ready(function () {
              setTimeout(function() {
                cookieconsent.run({"notice_banner_type":"interstitial","consent_type":"express","palette":"light","language":"en","cookies_policy_url":"/privacy-policy.html"});
              }, 100);
            });
          `}
        </script>

        {/* Google Analytics */}
        <script
          type="text/plain"
          cookie-consent="tracking"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JJC247ZDC2"
        ></script>
        <script type="text/plain" cookie-consent="tracking">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-JJC247ZDC2', { 'anonymize_ip': true });
          `}
        </script>
        {/* end of Google Analytics*/}

        {/* Google Adsense */}
        <script
          type="text/plain"
          cookie-consent="targeting"
          data-ad-client="ca-pub-1684454471956316"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
        {/* end of Google Adsense*/}

        <noscript>
          {`
            Cookie Consent by{" "}
            <a
              href="https://www.FreePrivacyPolicy.com/free-cookie-consent/"
              rel="nofollow noopener"
            >
              FreePrivacyPolicy.com
            </a>
          `}
        </noscript>
        {/* End Cookie Consent */}
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
